<template>
  <c-box flexGrow="1">
    <c-breadcrumb
      fontSize="14px"
      lineHeight="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="brand.900"
          as="router-link"
          to="/admin/coaching-challenge"
        >
          Manajemen Diet Coaching & Challenge
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link isCurrent>
          Detail Diet Coaching & Challenge
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-flex justify="space-between" align="center" mb="30px">
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Detail Konten
      </c-heading>
      <c-box>
        <c-button
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="isOpen = true"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
        <c-button
          variant="solid"
          variant-color="brand"
          border-radius="60px"
          h="auto"
          min-w="114px"
          py="13px"
          px="18px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          as="router-link"
          :to="{
            name: 'admin.coaching-challenge.edit',
            params: { id: id },
          }"
        >
          <c-image
            :src="require('@/assets/icon-pencil.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Edit
        </c-button>
      </c-box>
    </c-flex>

    <c-box>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Tipe
        </c-text>
        <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
          {{ type }}
        </c-text>
      </c-form-control>
      <c-form-control v-if="item.type === 'daily_coaching'" mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Tampil Hari Ke
        </c-text>
        <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
          Hari ke-{{ item.showOnDay }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Judul
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-weight="500"
          font-size="24px"
          line-height="36px"
        >
          {{ item.title }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Kategori
        </c-text>
        <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
          {{ category }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Deskripsi
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          word-break="break-word"
          v-html="item.description"
        />
      </c-form-control>
      <c-form-control v-if="imagePreview.length > 0" mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Preview Cover Program
        </c-text>
        <c-flex
          v-for="(item, index) in imagePreview"
          :key="'preview-' + index"
          mb="28px"
        >
          <c-box mr="30" min-w="230px" w="230px">
            <c-image
              :src="getPhotoProgram(item.url)"
              alt="file program"
              mx="auto"
              border-radius="6px"
            />
          </c-box>
        </c-flex>
      </c-form-control>
      <c-form-control v-if="images.length > 0" mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Gambar dan Caption
        </c-text>
        <c-flex v-for="(item, index) in images" :key="'img-' + index" mb="28px">
          <c-box mr="30" min-w="230px" w="230px">
            <c-image
              :src="getPhotoProgram(item.url)"
              alt="file program"
              mx="auto"
              border-radius="6px"
            />
          </c-box>
          <c-text
            color="superDarkGray.900"
            font-weight="normal"
            font-size="18px"
            line-height="27px"
          >
            {{ item.caption }}
          </c-text>
        </c-flex>
      </c-form-control>
      <c-form-control v-if="videos.length > 0" mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Video dan Caption
        </c-text>
        <c-flex
          v-for="(item, index) in videos"
          :key="'video-' + index"
          mb="28px"
        >
          <c-box mr="30" min-w="387px" min-h="218px">
            <video
              v-chakra
              w="100%"
              h="100%"
              :src="item.url"
              controls
              muted="muted"
            />
          </c-box>
          <c-text
            color="superDarkGray.900"
            font-weight="normal"
            font-size="18px"
            line-height="27px"
          >
            {{ item.caption }}
          </c-text>
        </c-flex>
      </c-form-control>
      <c-form-control v-if="youtube.length > 0" mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          YouTube
        </c-text>
        <c-box
          v-for="(item, index) in youtube"
          :key="'youtube-' + index"
          mb="28px"
          mr="30"
        >
          <youtube :video-id="getYoutubeId(item.url)"></youtube>
        </c-box>
      </c-form-control>
      <c-form-control v-if="files.length > 0" mb="30px">
        <c-text
          mb="2px"
          color="brand.900"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          File dan Dokumen
        </c-text>
        <c-flex v-for="(item, index) in files" :key="'file-' + index" mb="28px">
          <c-flex
            align-items="center"
            justify-content="center"
            h="100px"
            w="100px"
            mr="20px"
            bg="#F4CC4619"
            border-radius="12px"
          >
            <c-image
              w="50px"
              h="50px"
              :src="require('@/assets/icon-folder.svg')"
              alt="icon-folder"
            />
          </c-flex>
          <c-flex
            justify-content="center"
            align-items="baseline"
            flex-dir="column"
            flex="1"
          >
            <c-text
              font-size="24px"
              line-height="150%"
              text-align="justify"
              color="#333333"
            >
              {{ item.fileName }}
            </c-text>
            <c-text font-size="18px" line-height="150%" color="#888888">
              {{ formatBytes(item.fileSize) }}
            </c-text>
          </c-flex>
        </c-flex>
      </c-form-control>
    </c-box>

    <DeleteConfirm
      @on-delete="onDelete"
      :content="deletedText"
      :isOpen="isOpen"
    />
  </c-box>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import generalMixin from "@/utils/general-mixins";
import DeleteConfirm from "./../delete-confirm.vue";

export default {
  name: "AdminCoachingChallengeDetail",
  components: { DeleteConfirm },
  mixins: [generalMixin],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState({
      categoryContent: (s) => s.admContentChallenge.category,
      categoryCoaching: (s) => s.admContentChallenge.categoryCoaching,
      types: (s) => s.admContentChallenge.types,
    }),
    ...mapGetters({
      axios: "axios",
      item: "admContentChallenge/item",
    }),
    id() {
      return this.$route.params.id;
    },
    type() {
      return this.types.find((dt) => dt.value === this.item.type)?.label;
    },
    category() {
      if (this.item.type === "coaching_course") {
        return this.categoryCoaching.find(
          (it) => it.value === this.item.category
        )?.label;
      }
      return this.categoryContent.find((it) => it.value === this.item.category)
        ?.label;
    },
    imagePreview() {
      if (this.item && this.item.media) {
        return this.item.media.filter(
          (dt) => dt.type === "image" && dt.preview
        );
      }
      return [];
    },
    images() {
      if (this.item && this.item.media) {
        return this.item.media.filter(
          (dt) => dt.type === "image" && !dt.preview
        );
      }
      return [];
    },
    videos() {
      if (this.item && this.item.media) {
        return this.item.media.filter((dt) => dt.type === "video");
      }
      return [];
    },
    youtube() {
      if (this.item && this.item.media) {
        return this.item.media.filter((dt) => dt.type === "youtube");
      }
      return [];
    },
    files() {
      if (this.item && this.item.media) {
        return this.item.media.filter((dt) => dt.type === "file");
      }
      return [];
    },
    deletedText() {
      return `Anda yakin ingin hapus Konten <br /> “${this.item.title}”?`;
    },
  },
  methods: {
    ...mapActions({
      detailContentChallenge: "admContentChallenge/detailContentChallengeAdmin",
      deleteContentChallenge: "admContentChallenge/deleteContentChallengeAdmin",
    }),
    closeDialog() {
      this.isOpen = false;
    },
    onDelete(state) {
      this.isOpen = state;
      if (!this.isOpen) return;
      let type = this.item.type;
      this.deleteContentChallenge(this.id)
        .then(() => {
          this.$toast({
            title: "Success",
            description: "Content has been deleted.",
            status: "success",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
          this.$router.push({
            name: "admin.coaching-challenge",
            query: { type: type },
          });
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message ?? "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
  },
  async mounted() {
    this.detailContentChallenge(this.id);
  },
};
</script>
<style>
table {
  width: 100%;
  border: thin solid #c4c4c4;
}
table tr th {
  border-left: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
  padding: 4px 8px;
}
table tr td {
  border-left: thin solid #c4c4c4;
  border-bottom: thin solid #c4c4c4;
  padding: 4px 8px;
}
</style>